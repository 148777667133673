<template>
<section class='slager_m'>
   <div class="banner">
      <a :href="url" :class="{'en_a':$i18n.locale == 'en'}">
         <span>{{ $t("PC.Product.Slager.z3x2z7") }}</span>
      </a>
      <router-link
         class="price"
         to="SlagerPrice"
         :class="{'en_a':$i18n.locale == 'en'}">
         <span>{{$t("PC.Product.Slager.a4q7q8")}}</span>
      </router-link>
      <img class="bg" :src="$i18n.locale=='en'?en_banner:zh_banner" alt="">
   </div>
   <ul class="contain_1">
      <p class="big_title">{{ $t("PC.Product.Slager.v1zo8l") }}</p>
      <p class="x">
         {{ $t("PC.Product.Slager.mz3h79") }}</p>
      <div class="advantage_scroll">
         <div class="advantage_box">
            <svg class="iconsymbol" aria-hidden="true">
               <use xlink:href="#iconslager_gaoxiao1"></use>
            </svg>
            <p class="t1">{{ $t("PC.Product.Slager.he-zuo") }}</p>
            <p class="t2">{{ $t("PC.Product.Slager.6z4xdp") }}</p>
         </div>
         <div class="advantage_box">
            <svg class="iconsymbol" aria-hidden="true">
               <use xlink:href="#iconslager_gaoxiao"></use>
            </svg>
            <p class="t1">{{ $t("PC.Product.Slager.zaaxhj") }}</p>
            <p class="t2">{{ $t("PC.Product.Slager.dgu1nr") }}</p>
         </div>
         <div class="advantage_box">
            <svg class="iconsymbol" aria-hidden="true">
               <use xlink:href="#iconslager_work"></use>
            </svg>
            <p class="t1">{{ $t("PC.Product.Slager.3bwoso") }}</p>
            <p class="t2">{{ $t("PC.Product.Slager.jbmulg") }}</p>
         </div>
         <div class="advantage_box">
            <svg class="iconsymbol" aria-hidden="true">
               <use xlink:href="#iconslager_gongxiang"></use>
            </svg>
            <p class="t1">{{ $t("PC.Product.Slager.4xp0f3") }}</p>
            <p class="t2">{{ $t("PC.Product.Slager.nk7i2t") }}</p>
         </div>
      </div>
   </ul>
   <div class="contain_2">
      <p class="ct" :class="{'enbreak':$i18n.locale=='en'}">{{ $t("PC.Product.Slager.4sxowr") }}</p>
      <p class="x">{{ $t("PC.Product.Slager.sugyqn") }}</p>
      <div class="bigbox">
         <div class="box">
            <svg class="iconsymbol" aria-hidden="true">
               <use xlink:href="#iconslager_paiban"></use>
            </svg>
            <p class="title">{{ $t("PC.Product.Slager.nrp7mg") }}</p>
            <p class="subtitle">{{ $t("PC.Product.Slager.1m2ing") }}</p>
         </div>
         <div class="box">
            <svg class="iconsymbol" aria-hidden="true">
               <use xlink:href="#iconslager_aishibie"></use>
            </svg>
            <p class="title">{{ $t("PC.Product.Slager.m1esmc") }}</p>
            <p class="subtitle">{{ $t("PC.Product.Slager.cmp71p") }}</p>
         </div>
         <div class="box">
            <svg class="iconsymbol" aria-hidden="true">
               <use xlink:href="#iconslager_zhuanhuan"></use>
            </svg>
            <p class="title">Word-to-LaTeX</p>
            <p class="subtitle">
               {{ $t("PC.Product.Slager.61zxzb") }}</p>
         </div>
         <div class="box">
            <svg class="iconsymbol" aria-hidden="true">
               <use xlink:href="#iconslager_xiezuo"></use>
            </svg>
            <p class="title">{{ $t("PC.Product.Slager.y6x0rr") }}</p>
            <p class="subtitle">
               {{ $t("PC.Product.Slager.tkditn") }}</p>
         </div>
         <div class="box">
            <svg class="iconsymbol" aria-hidden="true">
               <use xlink:href="#iconslager_moban"></use>
            </svg>
            <p class="title">{{ $t("PC.Product.Slager.zrx1bp") }}</p>
            <p class="subtitle">
               {{ $t("PC.Product.Slager.u2fbb5") }}</p>
         </div>
         <div class="box">
            <svg class="iconsymbol" aria-hidden="true">
               <use xlink:href="#iconslager_linggan"></use>
            </svg>
            <p class="title">{{ $t("PC.Product.Slager.0h8eby") }}</p>
            <p class="subtitle">
               {{ $t("PC.Product.Slager.9uh8p9") }}</p>
         </div>
      </div>
   </div>
   <FooterB></FooterB>
</section>
</template>

<script>
import FooterB from "../components/FooterB";
export default {
   components: {
      FooterB,
   },
   data() {
      return {
         url: Global.SLAGER_URL,
         en_banner: require("../../../assets/en/images-mobile/banner_mb.jpg"),
         zh_banner: require("../../../assets/zh/images-mobile/banner_mb.jpg"),
      }
   },
};
</script>

<style lang="scss" scoped>
.slager_m {
   margin-top: 48px;
   word-break: break-word !important;

   .banner {
      position: relative;

      a {
         position: absolute;
         top: 84px;
         left: 30px;
         width: 60px;
         height: 19px;
         border: 1px solid #fff;

         span {
            color: #fff;
            display: inline-block;
            width: 100%;
            font-family:   PingFangSC-Semibold, PingFang SC;
            text-align: center;
            line-height: 18px;
            vertical-align: top;
            font-size: 8px;
            transform: scale(.7);
            transform-origin: center center;
         }
      }

      .price {
         position: absolute;
         top: 84px;
         left: 103px;
         background: transparent;
         border: 1px solid #FFFFFF;
         border-radius: 1px;
         width: 60px;
         height: 19px;

         span {
            color: #fff;
            display: inline-block;
            width: 100%;
            font-family:   PingFangSC-Semibold, PingFang SC;
            text-align: center;
            line-height: 18px;
            vertical-align: top;
            font-size: 8px;
            transform: scale(.7);
            transform-origin: center center;
         }

      }

      .en_a {
         top: 94px;
      }

      img.bg {
         width: 100%;
         height: 150px;
      }
   }

   ul.contain_1 {
      box-sizing: border-box;
      padding: 20px;
      padding-bottom: 10px;

      p.x {
         font-size: 12px;
         color: #333;
         line-height: 24px;
         text-align: justify;
         letter-spacing: 0.2px;
         padding-bottom: 16px;
      }

      &>p.big_title {
         color: #333;
         text-align: center;
         padding-bottom: 8px;
         font-size: 16px;
         line-height: 22px;

      }

      .advantage_scroll {
         white-space: nowrap;
         overflow-x: auto;
         padding-bottom: 10px;

         &::-webkit-scrollbar {
            width: 0px
         }

         .advantage_box {
            max-width: 190px;
            display: inline-block;
            text-align: center;
            margin-right: 12px;

            .iconsymbol {
               width: 52px;
               height: 52px;
            }

            .t1 {
               color: #333333;
               font-size: 14px;
               font-family: PingFangSC-Medium, PingFang SC;
               line-height: 20px;
            }

            .t2 {
               width: 100%;
               white-space: pre-wrap;
               font-size: 12px;
               font-family: PingFangSC-Regular, PingFang SC;
               color: #666666;
               line-height: 17px;
            }
         }
      }
   }

   div.contain_2 {
      width: 100%;
      background: #F5F6F9;
      padding: 16px 20px 11px;

      p.ct {
         font-size: 16px;
         font-weight: 500;
         color: #333;
         line-height: 22px;
         text-align: center;
         margin-bottom: 8px;
      }

      .enbreak {
         width: 320px;
         margin: 0 auto;
      }

      p.x {
         font-size: 12px;
         color: #333;
         line-height: 24px;
         text-align: justify;
         letter-spacing: 0.2px;
         margin-bottom: 12px;
      }

      .bigbox {
         display: flex;
         flex-wrap: wrap;
         justify-content: space-between;
         align-items: center;

         div.box {
            width: 335px;
            // height: 193px;
            background: #FFFFFF;
            border-radius: 3px;
            padding: 20px;
            box-sizing: border-box;
            text-align: left;
            margin-bottom: 16px;

            .iconsymbol {
               width: 50px;
               height: 50px;
               margin-bottom: 12px;
            }

            .title {
               font-size: 14px;
               font-family: PingFangSC-Medium, PingFang SC;
               color: #333333;
               line-height: 20px;
               margin-bottom: 8px;
            }

            .subtitle {
               font-size: 12px;
               font-family: PingFangSC-Regular, PingFang SC;
               color: #666666;
               line-height: 21px;
            }
         }
      }

   }
}
</style>
